import { default as indexMScMo3Bi82Meta } from "/opt/apps/web/pages/index.vue?macro=true";
import { default as indexOJthvvQICIMeta } from "/opt/apps/web/pages/[place]/[parking]/index.vue?macro=true";
import { default as reservation78rD0Na45AMeta } from "/opt/apps/web/pages/[place]/[parking]/reservation.vue?macro=true";
import { default as optionsNbWvMnAJvdMeta } from "/opt/apps/web/pages/[place]/[parking]/reservation2/options.vue?macro=true";
import { default as index4Js8ZpGldgMeta } from "/opt/apps/web/pages/[place]/index.vue?macro=true";
import { default as _91slug_93O6AhxQoAhnMeta } from "/opt/apps/web/pages/blog/[slug].vue?macro=true";
import { default as index98QeOwUcAaMeta } from "/opt/apps/web/pages/blog/index.vue?macro=true";
import { default as _91page_932UxFcpIw7PMeta } from "/opt/apps/web/pages/blog/pages/[page].vue?macro=true";
import { default as billingKbwuMOVndIMeta } from "/opt/apps/web/pages/bookings/[booking]/edit/billing.vue?macro=true";
import { default as detailsqxjFM32W0KMeta } from "/opt/apps/web/pages/bookings/[booking]/edit/details.vue?macro=true";
import { default as indexM4QM0mzyG0Meta } from "/opt/apps/web/pages/bookings/[booking]/index.vue?macro=true";
import { default as manage36CTewKD4FMeta } from "/opt/apps/web/pages/bookings/manage.vue?macro=true";
import { default as remindNm1DafxZJqMeta } from "/opt/apps/web/pages/bookings/remind.vue?macro=true";
import { default as contactvvuKCl6KMqMeta } from "/opt/apps/web/pages/contact.vue?macro=true";
import { default as confirmnICy3oYLiTMeta } from "/opt/apps/web/pages/customer/auth/confirm.vue?macro=true";
import { default as login91h2PlO8lmMeta } from "/opt/apps/web/pages/customer/auth/login.vue?macro=true";
import { default as registerunqX1iDHCFMeta } from "/opt/apps/web/pages/customer/auth/register.vue?macro=true";
import { default as remindMCWxjawFBZMeta } from "/opt/apps/web/pages/customer/auth/remind.vue?macro=true";
import { default as index3gbo3Mz4syMeta } from "/opt/apps/web/pages/customer/bookings/index.vue?macro=true";
import { default as index8US3NjamWtMeta } from "/opt/apps/web/pages/customer/extras/index.vue?macro=true";
import { default as indexkakOF1Vn9KMeta } from "/opt/apps/web/pages/customer/index.vue?macro=true";
import { default as index32ThOmjcByMeta } from "/opt/apps/web/pages/customer/settings/index.vue?macro=true";
import { default as passwordIoSvqLeejtMeta } from "/opt/apps/web/pages/customer/settings/password.vue?macro=true";
import { default as indexXeZ93U622aMeta } from "/opt/apps/web/pages/customer/vehicles/index.vue?macro=true";
import { default as _91category_93bvu4iHBcIqMeta } from "/opt/apps/web/pages/faq/[category].vue?macro=true";
import { default as indexhZWYNULP0yMeta } from "/opt/apps/web/pages/faq/index.vue?macro=true";
import { default as searchDuVVLezbP7Meta } from "/opt/apps/web/pages/faq/search.vue?macro=true";
import { default as gps5yVmzUQYKRMeta } from "/opt/apps/web/pages/gps.vue?macro=true";
import { default as extras_45airclaimsWov3U3KAsLMeta } from "/opt/apps/web/pages/others/extras-airclaims.vue?macro=true";
import { default as extras_45carrentalsJ1CBQehrSIMeta } from "/opt/apps/web/pages/others/extras-carrentals.vue?macro=true";
import { default as extras_45travelinsuranceXpT92s2NIYMeta } from "/opt/apps/web/pages/others/extras-travelinsurance.vue?macro=true";
import { default as howitworksE15LWoqtyOMeta } from "/opt/apps/web/pages/others/howitworks.vue?macro=true";
import { default as howtoselectcOWQYgrDRpMeta } from "/opt/apps/web/pages/others/howtoselect.vue?macro=true";
import { default as rules_45privacy2aML1pbIzVMeta } from "/opt/apps/web/pages/others/rules-privacy.vue?macro=true";
import { default as rules_45termsPzHTewMHRIMeta } from "/opt/apps/web/pages/others/rules-terms.vue?macro=true";
import { default as whywe6d6oRgk7FhMeta } from "/opt/apps/web/pages/others/whywe.vue?macro=true";
import { default as airportsn5yXkl66uzMeta } from "/opt/apps/web/pages/parkings/airports.vue?macro=true";
import { default as stationsJlib5hFFrHMeta } from "/opt/apps/web/pages/parkings/stations.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    meta: indexMScMo3Bi82Meta || {},
    component: () => import("/opt/apps/web/pages/index.vue")
  },
  {
    name: "place-parking___el",
    path: "/el/:place()/:parking()/",
    meta: indexOJthvvQICIMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/index.vue")
  },
  {
    name: "place-parking-reservation___el",
    path: "/el/:place()/:parking()/reservation/",
    meta: reservation78rD0Na45AMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation.vue")
  },
  {
    name: "place-parking-reservation2-options___el",
    path: "/el/:place()/:parking()/reservation2/options/",
    component: () => import("/opt/apps/web/pages/[place]/[parking]/reservation2/options.vue")
  },
  {
    name: "place___el",
    path: "/el/:place()/",
    meta: index4Js8ZpGldgMeta || {},
    component: () => import("/opt/apps/web/pages/[place]/index.vue")
  },
  {
    name: "blog-slug___el",
    path: "/el/blog/:slug()/",
    meta: _91slug_93O6AhxQoAhnMeta || {},
    component: () => import("/opt/apps/web/pages/blog/[slug].vue")
  },
  {
    name: "blog___el",
    path: "/el/blog/",
    meta: index98QeOwUcAaMeta || {},
    component: () => import("/opt/apps/web/pages/blog/index.vue")
  },
  {
    name: "blog-pages-page___el",
    path: "/el/blog/pages/:page()/",
    meta: _91page_932UxFcpIw7PMeta || {},
    component: () => import("/opt/apps/web/pages/blog/pages/[page].vue")
  },
  {
    name: "bookings-booking-edit-billing___el",
    path: "/el/bookings/:booking()/edit/billing/",
    meta: billingKbwuMOVndIMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/billing.vue")
  },
  {
    name: "bookings-booking-edit-details___el",
    path: "/el/bookings/:booking()/edit/details/",
    meta: detailsqxjFM32W0KMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/edit/details.vue")
  },
  {
    name: "bookings-booking___el",
    path: "/el/bookings/:booking()/",
    meta: indexM4QM0mzyG0Meta || {},
    component: () => import("/opt/apps/web/pages/bookings/[booking]/index.vue")
  },
  {
    name: "bookings-manage___el",
    path: "/el/bookings/manage/",
    meta: manage36CTewKD4FMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/manage.vue")
  },
  {
    name: "bookings-remind___el",
    path: "/el/bookings/remind/",
    meta: remindNm1DafxZJqMeta || {},
    component: () => import("/opt/apps/web/pages/bookings/remind.vue")
  },
  {
    name: "contact___el",
    path: "/el/epikoinwnia/",
    meta: contactvvuKCl6KMqMeta || {},
    component: () => import("/opt/apps/web/pages/contact.vue")
  },
  {
    name: "customer-auth-confirm___el",
    path: "/el/customer/auth/confirm/",
    component: () => import("/opt/apps/web/pages/customer/auth/confirm.vue")
  },
  {
    name: "customer-auth-login___el",
    path: "/el/customer/auth/login/",
    meta: login91h2PlO8lmMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/login.vue")
  },
  {
    name: "customer-auth-register___el",
    path: "/el/customer/auth/register/",
    meta: registerunqX1iDHCFMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/register.vue")
  },
  {
    name: "customer-auth-remind___el",
    path: "/el/customer/auth/remind/",
    meta: remindMCWxjawFBZMeta || {},
    component: () => import("/opt/apps/web/pages/customer/auth/remind.vue")
  },
  {
    name: "customer-bookings___el",
    path: "/el/customer/bookings/",
    meta: index3gbo3Mz4syMeta || {},
    component: () => import("/opt/apps/web/pages/customer/bookings/index.vue")
  },
  {
    name: "customer-extras___el",
    path: "/el/customer/extras/",
    meta: index8US3NjamWtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/extras/index.vue")
  },
  {
    name: "customer___el",
    path: "/el/customer/",
    meta: indexkakOF1Vn9KMeta || {},
    component: () => import("/opt/apps/web/pages/customer/index.vue")
  },
  {
    name: "customer-settings___el",
    path: "/el/customer/settings/",
    meta: index32ThOmjcByMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/index.vue")
  },
  {
    name: "customer-settings-password___el",
    path: "/el/customer/settings/password/",
    meta: passwordIoSvqLeejtMeta || {},
    component: () => import("/opt/apps/web/pages/customer/settings/password.vue")
  },
  {
    name: "customer-vehicles___el",
    path: "/el/customer/vehicles/",
    meta: indexXeZ93U622aMeta || {},
    component: () => import("/opt/apps/web/pages/customer/vehicles/index.vue")
  },
  {
    name: "faq-category___el",
    path: "/el/faq/:category()/",
    meta: _91category_93bvu4iHBcIqMeta || {},
    component: () => import("/opt/apps/web/pages/faq/[category].vue")
  },
  {
    name: "faq___el",
    path: "/el/faq/",
    meta: indexhZWYNULP0yMeta || {},
    component: () => import("/opt/apps/web/pages/faq/index.vue")
  },
  {
    name: "faq-search___el",
    path: "/el/faq/search/",
    meta: searchDuVVLezbP7Meta || {},
    component: () => import("/opt/apps/web/pages/faq/search.vue")
  },
  {
    name: "gps___el",
    path: "/el/gps/",
    meta: gps5yVmzUQYKRMeta || {},
    component: () => import("/opt/apps/web/pages/gps.vue")
  },
  {
    name: "index___el",
    path: "/el/",
    meta: indexMScMo3Bi82Meta || {},
    component: () => import("/opt/apps/web/pages/index.vue")
  },
  {
    name: "others-extras-airclaims___el",
    path: "/el/others/extras-airclaims/",
    meta: extras_45airclaimsWov3U3KAsLMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-airclaims.vue")
  },
  {
    name: "others-extras-carrentals___el",
    path: "/el/enoikiaseis-aytokinitwn/",
    meta: extras_45carrentalsJ1CBQehrSIMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-carrentals.vue")
  },
  {
    name: "others-extras-travelinsurance___el",
    path: "/el/asfalish-taksidiwn/",
    meta: extras_45travelinsuranceXpT92s2NIYMeta || {},
    component: () => import("/opt/apps/web/pages/others/extras-travelinsurance.vue")
  },
  {
    name: "others-howitworks___el",
    path: "/el/pws-leitoyrgei/",
    meta: howitworksE15LWoqtyOMeta || {},
    component: () => import("/opt/apps/web/pages/others/howitworks.vue")
  },
  {
    name: "others-howtoselect___el",
    path: "/el/pws-na-epilexete-xwro-stathmeysis/",
    meta: howtoselectcOWQYgrDRpMeta || {},
    component: () => import("/opt/apps/web/pages/others/howtoselect.vue")
  },
  {
    name: "others-rules-privacy___el",
    path: "/el/politikh-aporritoy/",
    meta: rules_45privacy2aML1pbIzVMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-privacy.vue")
  },
  {
    name: "others-rules-terms___el",
    path: "/el/oroi/",
    meta: rules_45termsPzHTewMHRIMeta || {},
    component: () => import("/opt/apps/web/pages/others/rules-terms.vue")
  },
  {
    name: "others-whywe___el",
    path: "/el/giati-nextpark/",
    meta: whywe6d6oRgk7FhMeta || {},
    component: () => import("/opt/apps/web/pages/others/whywe.vue")
  },
  {
    name: "parkings-airports___el",
    path: "/el/xwroi-stathmeysis-aerodromiwn/",
    meta: airportsn5yXkl66uzMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/airports.vue")
  },
  {
    name: "parkings-stations___el",
    path: "/el/xwroi-stathmeysis-stathmwn/",
    meta: stationsJlib5hFFrHMeta || {},
    component: () => import("/opt/apps/web/pages/parkings/stations.vue")
  }
]